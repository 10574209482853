import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { main } from './configuration';

import AppContainer from './features/app/App.container';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { store } from './configuration/setup/store';
import { RioAuthProvider } from './auth/RioAuthProvider';

const renderApplication = () => {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <RioAuthProvider>
                        <AppContainer />
                    </RioAuthProvider>
                </HashRouter>
            </Provider>
        </ErrorBoundary>
    );
};

main(renderApplication);
