import type { Client, RepoApiSpec } from '../App.types';

export interface PartnerPermissionTmpl {
    service: string;
    permissions: string[] | undefined;
}

export interface BackendPermissionStatement {
    service: string;
    statements: PermissionStatement[];
}

interface PermissionStatement {
    permissions: string[];
    scope: PermissionScope;
}

type PermissionScope = AccountScope | GlobalScope | TagScope;

interface AccountScope {
    type: 'account';
    identifiers: string[];
}

interface TagScope {
    type: 'tag';
    identifiers: string[];
}

interface GlobalScope {
    type: 'global';
}

// type-guards
export const isPartnerPermissionTmpl = (obj: unknown): obj is PartnerPermissionTmpl =>
    typeof obj === 'object' &&
    obj !== null &&
    'service' in obj &&
    typeof obj.service === 'string' &&
    'permissions' in obj;

export const isBackendPermissionStatement = (obj: unknown): obj is BackendPermissionStatement =>
    typeof obj === 'object' &&
    obj !== null &&
    'service' in obj &&
    typeof obj.service === 'string' &&
    'statements' in obj;

const isString = (val: string | undefined): val is string => !!val;
export const generatePermissionStatement = (
    apiSpec: RepoApiSpec,
    clientType: Client,
    accountId?: string
): PartnerPermissionTmpl | BackendPermissionStatement | undefined => {
    if (apiSpec.permissions === undefined) {
        return undefined;
    }
    switch (clientType) {
        case 'Partner':
            return { service: apiSpec.name, permissions: apiSpec.permissions };
        case 'Backend':
            return {
                service: apiSpec.name,
                statements: [
                    {
                        permissions: apiSpec.permissions,
                        scope: accountId
                            ? {
                                  type: 'account',
                                  identifiers: [accountId],
                              }
                            : { type: 'global' },
                    },
                ],
            };
        default:
            return undefined;
    }
};

export const removeDuplicateScopes = (inputScopes: string[]) => Array.from(new Set(inputScopes)).sort();

export const cleanUpPermissionTemplates = (inputApis: (PartnerPermissionTmpl | BackendPermissionStatement)[]) => {
    const servicesWithApi = new Set(inputApis.map(it => it.service));

    const cleanedPermissionTemplates = [];

    for (const service of servicesWithApi) {
        const serviceSpecificApis = inputApis.filter(tpl => tpl.service === service);
        if (serviceSpecificApis.length > 1) {
            const mergedPermissions = Array.from(
                new Set(
                    serviceSpecificApis.flatMap(it => {
                        if (isPartnerPermissionTmpl(it)) {
                            return it.permissions;
                        }
                        if (isBackendPermissionStatement(it)) {
                            return it.statements.flatMap(it => it.permissions);
                        }
                        return [];
                    })
                )
            ).sort();
            if (isBackendPermissionStatement(serviceSpecificApis[0])) {
                cleanedPermissionTemplates.push({
                    service,
                    // TODO fix for account scope
                    statements: [
                        {
                            permissions: mergedPermissions.filter(isString),
                            scope: { type: serviceSpecificApis[0].statements[0].scope.type },
                        },
                    ],
                });
            } else {
                cleanedPermissionTemplates.push({ service, permissions: mergedPermissions.filter(isString) });
            }
        } else {
            cleanedPermissionTemplates.push(...serviceSpecificApis);
        }
    }

    return cleanedPermissionTemplates;
};

export const mapTypeToGrantType = (type: Client) => {
    switch (type) {
        case 'Partner':
            return 'partner_integration';
        case 'Backend':
            return 'client_credentials';
        case 'SPA':
            return 'authorization_code';
        default:
            return '';
    }
};
