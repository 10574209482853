import { useSelector } from 'react-redux';
import { getClientType } from './clientRequest.selectors';
import { PartnerClientApiSelection } from './PartnerClientApiSelection';
import { NavigationButtons } from './NavigationButtons';
import { CustomScopeField } from './CustomScopeField';
import { CustomPermissions } from './CustomPermissions';

export const Step2 = () => {
    const clientType = useSelector(getClientType);
    const renderApiSelection = () => {
        switch (clientType) {
            case 'Partner':
                return <PartnerClientApiSelection />;
            case 'Backend':
                return (
                    <div>
                        <PartnerClientApiSelection />
                        <CustomScopeField />
                        <CustomPermissions />
                    </div>
                );
            case 'SPA':
                return <CustomScopeField />;
            default:
                return null;
        }
    };
    return (
        <>
            {renderApiSelection()}
            <NavigationButtons nextStepDisabled={false} />
        </>
    );
};
