import { Component, type ErrorInfo, type ReactNode } from 'react';

export class ErrorBoundary extends Component<{ children?: ReactNode }> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.warn(error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}
