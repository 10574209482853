import { createSlice } from '@reduxjs/toolkit';
import { config } from '../../config';
import type { RootState } from './store';

const configSlice = createSlice({
    name: 'config',
    initialState: config,
    reducers: {},
});

export const getConfig = (state: RootState) => state.config;

export default configSlice.reducer;
