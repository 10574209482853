import { useState } from 'react';
import {
    getClientAccountId,
    getClientDescription,
    getClientDetailedDescription,
    getClientEmail,
    getClientName,
    getClientTrusted,
    getClientType,
    getIsMobileAppClient,
    getRequiresPermissions,
} from './clientRequest.selectors';
import {
    resetClientAccount,
    toggleClientRequestPermissions,
    toggleClientTrusted,
    toggleIsMobileApp,
    updateClientAccount,
    updateClientDescription,
    updateClientDetailedDescription,
    updateClientEmail,
    updateClientName,
} from './clientRequestSlice';
import { NavigationButtons } from './NavigationButtons';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { InputField } from './InputField';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

const ClientTrustedCheckbox = () => {
    const dispatch = useAppDispatch();
    const trusted = useAppSelector(getClientTrusted);
    return (
        <Checkbox className={'margin-left-20'} onClick={() => dispatch(toggleClientTrusted())} checked={trusted}>
            Client trusted (RIO/MAN)
        </Checkbox>
    );
};

const BackendClientFields = () => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector(getClientAccountId);
    const requiresPermissions = useAppSelector(getRequiresPermissions);

    const [showAccountField, setShowAccountField] = useState(!!accountId);

    return (
        <div className={'form-group margin-top-10'}>
            <fieldset name={'limit-client-to-account'}>
                <Checkbox
                    className={'margin-left-20'}
                    onClick={() => {
                        dispatch(resetClientAccount());
                        setShowAccountField(!showAccountField);
                    }}
                    checked={showAccountField}
                >
                    Connect client to specific account
                </Checkbox>
                {showAccountField && (
                    <InputField
                        id={'accountId'}
                        label={'Account Id'}
                        value={accountId}
                        onChange={event => dispatch(updateClientAccount(event))}
                    />
                )}
            </fieldset>
            <Checkbox
                className={'margin-left-20'}
                onClick={() => dispatch(toggleClientRequestPermissions())}
                checked={requiresPermissions}
            >
                Client requires permissions
            </Checkbox>
            <ClientTrustedCheckbox />
        </div>
    );
};

const SpaClientFields = () => {
    const dispatch = useAppDispatch();
    const isMobileAppClient = useAppSelector(getIsMobileAppClient);
    return (
        <div>
            <Checkbox
                className={'margin-left-20'}
                onClick={() => dispatch(toggleIsMobileApp())}
                checked={isMobileAppClient}
            >
                Mobile App Client (requires PKCE)
            </Checkbox>
            <ClientTrustedCheckbox />
        </div>
    );
};

export const Step1 = () => {
    const clientType = useAppSelector(getClientType);
    const clientName = useAppSelector(getClientName);
    const clientEmail = useAppSelector(getClientEmail);
    const clientDescription = useAppSelector(getClientDescription);
    const clientDetailedDescription = useAppSelector(getClientDetailedDescription);

    const dispatch = useAppDispatch();

    const nextButtonDisabled = !clientName || !clientEmail || !clientDescription || !clientDetailedDescription;

    const renderBackendFields = () =>
        clientType === 'Backend' ? (
            <fieldset>
                <BackendClientFields />
            </fieldset>
        ) : null;

    const renderSpaFields = () =>
        clientType === 'SPA' ? (
            <fieldset>
                <SpaClientFields />
            </fieldset>
        ) : null;

    return (
        <>
            <div className={'margin-bottom-25'}>
                <h4>Enter Client Details</h4>
            </div>
            <form className={'form-horizontal'}>
                <fieldset>
                    <InputField
                        id={'clientName'}
                        label={'Client Name'}
                        value={clientName}
                        onChange={event => dispatch(updateClientName(event.target.value))}
                    />
                    <InputField
                        id={'clientEmail'}
                        label={'Client Email'}
                        value={clientEmail}
                        onChange={event => dispatch(updateClientEmail(event.target.value))}
                    />
                    <InputField
                        id={'description'}
                        label={'Description'}
                        value={clientDescription}
                        onChange={event => dispatch(updateClientDescription(event.target.value))}
                    />
                    <div className='form-group'>
                        <label htmlFor={'detailedDescription'} className={'control-label'}>
                            Detailed Description
                        </label>
                        <div>
                            <textarea
                                className={'form-control'}
                                rows={3}
                                id={'detailedDescription'}
                                value={clientDetailedDescription}
                                onChange={event => dispatch(updateClientDetailedDescription(event.target.value))}
                                placeholder={'Detailed Description'}
                            />
                        </div>
                    </div>
                </fieldset>
                {renderBackendFields()}
                {renderSpaFields()}
            </form>
            <NavigationButtons nextStepDisabled={nextButtonDisabled} />
        </>
    );
};
