// define selectors that work on local state slice

import type { Api } from './apiRepository';
import type { ClientDetails, ClientRequestState } from './clientRequest.types';
import type { RootState } from '../../../configuration/setup/store';

const getClientDetailsLocal = (state: ClientRequestState): ClientDetails => ({
    type: state.clientType,
    name: state.clientName,
    email: state.clientEmail,
    description: state.clientDescription,
    detailedDescription: state.clientDetailedDescription,
    accountId: state.associatedAccountId,
});

const getClientAccountIdLocal = (state: ClientRequestState) => state.associatedAccountId;
const getClientTrustedLocal = (state: ClientRequestState) => state.clientTrusted;
const getIsMobileAppClientLocal = (state: ClientRequestState) => state.isMobileAppClient;

const requiresPermissionsLocal = (state: ClientRequestState) => state.requiresPermissions;

const getCurrentStepLocal = (state: ClientRequestState) => state.currentStep;

export const getApiLocal = (state: ClientRequestState) => (api: Api) =>
    state.apiSelectionState[api] ? state.apiSelectionState[api] : { selected: false, read: false, write: false };

export const getApiSelectionStateLocal = (state: ClientRequestState) => state.apiSelectionState;

export const getCustomScopesLocal = (state: ClientRequestState) => state.customScopes;
export const getShowCustomScopesLocal = (state: ClientRequestState) => state.showCustomScopes;

// define global selectors

const getClientRequest = (state: RootState) => state.clientRequest;

export const getClientDetails = (state: RootState) => getClientDetailsLocal(getClientRequest(state));
export const getClientType = (state: RootState) => getClientDetails(state).type;
export const getClientName = (state: RootState) => getClientDetails(state).name;
export const getClientEmail = (state: RootState) => getClientDetails(state).email;
export const getClientDescription = (state: RootState) => getClientDetails(state).description;
export const getClientDetailedDescription = (state: RootState) => getClientDetails(state).detailedDescription;

export const getClientAccountId = (state: RootState) => getClientAccountIdLocal(getClientRequest(state));
export const getClientTrusted = (state: RootState) => getClientTrustedLocal(getClientRequest(state));
export const getIsMobileAppClient = (state: RootState) => getIsMobileAppClientLocal(getClientRequest(state));

export const getRequiresPermissions = (state: RootState) => requiresPermissionsLocal(getClientRequest(state));

export const getCurrentStep = (state: RootState) => getCurrentStepLocal(getClientRequest(state));

export const getApi = (state: RootState) => getApiLocal(getClientRequest(state));

export const getApiSelectionState = (state: RootState) => getApiSelectionStateLocal(getClientRequest(state));

export const getCustomScopes = (state: RootState) => getCustomScopesLocal(getClientRequest(state));
export const getShowCustomScopes = (state: RootState) => getShowCustomScopesLocal(getClientRequest(state));
export const getCustomScopesAsArray = (state: RootState) =>
    getCustomScopesLocal(getClientRequest(state))
        .replace(/\s+/g, '')
        .split(',')
        .filter(it => it); // only allow non-empty strings
