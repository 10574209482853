import {
    getApiSelectionState,
    getClientAccountId,
    getClientDetails,
    getClientType,
    getCustomScopesAsArray,
    getRequiresPermissions,
} from './clientRequest.selectors';
import { useSelector } from 'react-redux';
import { type Api, ApiRepository, getApiFromString } from './apiRepository';
import type { ReadOrWrite } from '../App.types';
import endent from 'endent';
import type { ClientDetails } from './clientRequest.types';
import {
    type BackendPermissionStatement,
    cleanUpPermissionTemplates,
    generatePermissionStatement,
    mapTypeToGrantType,
    type PartnerPermissionTmpl,
    removeDuplicateScopes,
} from './clientOutcomeUtils';

export const ClientOutcome = () => {
    const clientDetails = useSelector(getClientDetails);
    const selectedApis = useSelector(getApiSelectionState);
    const clientType = useSelector(getClientType);
    const requiresPermissions = useSelector(getRequiresPermissions);
    const customScopes = useSelector(getCustomScopesAsArray);

    const accountId = useSelector(getClientAccountId);

    const selectedApiScopes: string[] = [];
    const selectedApiPermissions: (PartnerPermissionTmpl | BackendPermissionStatement)[] = [];

    for (const api in selectedApis) {
        const selectedApi = getApiFromString(api);
        const selectedApiState = selectedApi ? selectedApis[selectedApi] : undefined;
        const access: Array<ReadOrWrite> = [];
        if (selectedApiState?.selected === true) {
            if (selectedApiState.read) access.push('read');
            if (selectedApiState.write) access.push('write');

            const apiSpec = ApiRepository.getApiSpecByName(selectedApi as Api, access);
            if (apiSpec) {
                apiSpec.permissions = Array.from(new Set(apiSpec.permissions));
            }
            if (apiSpec && access.length > 0) {
                selectedApiScopes.push(...apiSpec.scopes);
                if (apiSpec.permissions && apiSpec.permissions.length > 0) {
                    const permissionToAdd = generatePermissionStatement(apiSpec, clientType, accountId);
                    if (permissionToAdd) selectedApiPermissions.push(permissionToAdd);
                }
            }

            if (apiSpec?.additionalRequiredApis !== undefined) {
                const additionalPermissions: (PartnerPermissionTmpl | BackendPermissionStatement)[] = [];
                const additionalScopes: string[] = [];
                for (const it of apiSpec.additionalRequiredApis) {
                    const additionalApiSpecs = ApiRepository.getApiSpecByName(it.api, it.accessType);
                    if (additionalApiSpecs) {
                        const permissionStatement = generatePermissionStatement(
                            additionalApiSpecs,
                            clientType,
                            accountId
                        );
                        if (permissionStatement) additionalPermissions.push(permissionStatement);
                        additionalScopes.push(...additionalApiSpecs.scopes);
                    }
                }
                if (additionalPermissions.length > 0) {
                    selectedApiPermissions.push(...additionalPermissions);
                    selectedApiScopes.push(...additionalScopes);
                }
            }
        }
    }

    // add custom scopes
    selectedApiScopes.push(...customScopes);

    if (clientDetails.type === 'Partner') {
        return renderPartnerOutput(
            clientDetails,
            removeDuplicateScopes(selectedApiScopes),
            requiresPermissions,
            cleanUpPermissionTemplates(selectedApiPermissions)
        );
    }
    return renderNonPartnerOutput(
        clientDetails,
        removeDuplicateScopes(selectedApiScopes),
        requiresPermissions,
        cleanUpPermissionTemplates(selectedApiPermissions)
    );
};

const renderNonPartnerOutput = (
    clientDetails: ClientDetails,
    selectedApiScopes: string[],
    requiresPermissions: boolean,
    selectedApiPermissions: any[]
) => {
    const data: { [keys: string]: unknown } = {
        client_id: '',
        name: clientDetails.name,
        grant_types: [mapTypeToGrantType(clientDetails.type)],
        email: clientDetails.email,
        description: clientDetails.description,
        detailed_description: clientDetails.detailedDescription,
        scopes: selectedApiScopes,
    };

    if (requiresPermissions && selectedApiPermissions.length > 0) {
        data.client_credentials_permissions = selectedApiPermissions;
    }

    return <div>{JSON.stringify(data, null, 2)}</div>;
};

const renderPartnerOutput = (
    clientDetails: ClientDetails,
    selectedApiScopes: string[],
    requiresPermissions: boolean,
    selectedApiPermissions: any[]
) => {
    const prefix =
        clientDetails.type === 'Partner'
            ? '"partner_integration_permission_templates"'
            : '"client_credentials_permissions"';

    let jsonString: string;
    if (requiresPermissions && selectedApiScopes) {
        jsonString = endent`{
          "client_id": "",
          "name": "${clientDetails.name}",
          "grant_types": ["${mapTypeToGrantType(clientDetails.type)}"],
          "email": "${clientDetails.email.toLowerCase()}",
          "description": "${clientDetails.description}",
          "detailed_description": "${clientDetails.detailedDescription}",
          "scopes": ${endent.pretty(selectedApiScopes)},
          ${prefix}: ${endent.pretty(selectedApiPermissions)}
        }`;
    } else if (selectedApiScopes) {
        jsonString = endent`{
          "client_id": "",
          "name": "${clientDetails.name}",
          "grant_types": ["${mapTypeToGrantType(clientDetails.type)}"],
          "email": "${clientDetails.email.toLowerCase()}",
          "description": "${clientDetails.description}",
          "detailed_description": "${clientDetails.detailedDescription}",
          "scopes": ${endent.pretty(selectedApiScopes)},
        }`;
    } else {
        jsonString = endent`{
          "client_id": "",
          "name": "${clientDetails.name}",
          "grant_types": ["${mapTypeToGrantType(clientDetails.type)}"],
          "email": "${clientDetails.email}",
          "description": "${clientDetails.description}",
          "detailed_description": "${clientDetails.detailedDescription}"
        }`;
    }
    return <div>{jsonString}</div>;
};
