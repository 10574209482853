import type { ChangeEvent } from 'react';

export const InputField = (props: {
    value: string | undefined;
    label: string;
    id: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => (
    <div className='form-group'>
        <label htmlFor={props.id} className={'control-label'}>
            {props.label}
        </label>
        <div className={'margin-bottom-10'}>
            <input
                type={'text'}
                className={'form-control'}
                id={props.id}
                placeholder={props.label}
                value={props.value}
                onChange={props.onChange}
            />
        </div>
    </div>
);
