import { type PropsWithChildren, useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { config } from '../config';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { oauthConfig } from './oidcConfig';
import { ErrorResponse } from 'oidc-client-ts';
import { useAppSelector } from '../configuration/setup/hooks';
import { getWasLoggedInBefore } from '../configuration/login/loginSlice';
import { routeStorage } from '../configuration/login/storage';
import { trace } from '../configuration/setup/trace';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const isRunningInIframe = () => window.self !== window.top;

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
    routeStorage.saveRoute(initialRoute);
    trace('saving initial route', initialRoute);
};

export const AutoLogin = ({ children }: PropsWithChildren) => {
    const auth = useAuth();

    const wasLoggedInBefore = useAppSelector(getWasLoggedInBefore);

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !auth.error &&
            !config.login.mockAuthorization
        ) {
            // check if there is an active session and if so use it to sign in silently
            trace('AutoLogin: initiating silent login');
            void auth.signinSilent();
        }
    }, [auth]);

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            trace('AutoLogin: detected change in user session');
            oauthConfig.onSessionRenewed(auth.user);
        }
    }, [auth.isAuthenticated, auth.user]);

    useEffect(() => {
        if (
            auth.error &&
            auth.error instanceof ErrorResponse &&
            auth.error.error &&
            [
                // as per https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                'interaction_required',
                'account_selection_required',
                'consent_required',
                'login_required',
            ].includes(auth.error.error)
        ) {
            if (!wasLoggedInBefore && !isRunningInIframe()) {
                // perform initial login with redirect
                trace('AutoLogin: initiating initial login with redirect');
                saveCurrentRoute();
                void auth.signinRedirect();
            } else {
                trace('AutoLogin: could not successfully authenticate user, retrying in 30s');
                setTimeout(() => auth.signinSilent(), RETRY_SIGNIN_TIMEOUT_IN_MS);
            }
            // oauth provider needs some form of end-user interaction,
            // due to not being logged in, or similar reasons
            // => not a real error => ignore error so user can proceed to click "login" manually
        }
    }, [auth.error, auth.signinSilent, auth.signinRedirect, wasLoggedInBefore]);

    // some debugging log statements to monitor events
    useEffect(() => {
        return auth.events.addUserSignedIn(() => trace('user signed in'));
    }, [auth.events]);
    useEffect(() => {
        return auth.events.addUserLoaded(user => trace('AutoLogin: user loaded', user.profile.sub));
    }, [auth.events]);
    useEffect(() => {
        return auth.events.addUserSignedIn(() => trace('AutoLogin: user signed in'));
    }, [auth.events]);
    useEffect(() => {
        return auth.events.addUserSessionChanged(() => trace('AutoLogin: session changed'));
    }, [auth.events]);
    useEffect(() => {
        return auth.events.addUserUnloaded(() => trace('AutoLogin: user unloaded'));
    }, [auth.events]);
    useEffect(() => {
        return auth.events.addUserSignedOut(() => {
            // this function gets called when user signs out in another tab
            // and the session iframe indicates 'changed' status
            trace('AutoLogin: user signed out');
            oauthConfig.onSessionExpired();
        });
    }, [auth.events]);

    if ((auth.isLoading && !wasLoggedInBefore) || window.location.pathname === '/redirect') {
        return (
            <div className='height-100'>
                <div className='spinnerInfoBox display-flex justify-content-center align-items-center height-100pct'>
                    <div className='spinner'>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className='spinnerText'>Loading</div>
                </div>
            </div>
        );
    }

    if (!auth.isAuthenticated && !config.login.mockAuthorization && !wasLoggedInBefore) {
        return <div>Unable to log in</div>;
    }

    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => auth.signinSilent());
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => auth.signinSilent());

    return children;
};
