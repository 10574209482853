import { useSelector } from 'react-redux';
import { getClientDetails, getClientTrusted, getIsMobileAppClient } from './clientRequest.selectors';
import { NavigationButtons } from './NavigationButtons';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { ClientOutcome } from './ClientOutcome';

const copyFunction = (elementId: string) => () => {
    const inputElement = document.getElementById(elementId);
    const copyText = inputElement ? inputElement.textContent : '';
    if (copyText) {
        navigator.clipboard.writeText(copyText).then(() => {
            Notification.info('Copied to clipboard');
        });
    } else {
        Notification.warning('No data to copy found');
    }
};

export const Step3 = () => {
    const clientDetails = useSelector(getClientDetails);
    const isTrusted = useSelector(getClientTrusted);
    const isMobileAppClient = useSelector(getIsMobileAppClient);

    return (
        <>
            <div className={'margin-bottom-25'}>
                <h4>Copy the following and paste it in the ticket</h4>
            </div>
            <div>
                {clientDetails.type === 'Partner' ? (
                    <pre id={'outputArea'}>
                        <ClientOutcome />
                    </pre>
                ) : (
                    <div id={'outputArea'}>
                        <pre className={'margin-bottom-10'}>
                            {`Client Type: ${clientDetails.type}\n`}
                            {`Client Name: ${clientDetails.name}\n`}
                            {clientDetails.accountId && `Associated Account Id: ${clientDetails.accountId}\n`}
                            {isTrusted ? 'Client is trusted.\n' : 'Client is NOT trusted.\n'}
                            {isMobileAppClient && 'Client is used for a mobile app and requires PKCE.\n'}
                        </pre>
                        <pre>
                            <ClientOutcome />
                        </pre>
                    </div>
                )}
            </div>
            <button type={'button'} className={'btn btn-info'} onClick={copyFunction('outputArea')}>
                Copy
            </button>
            <NavigationButtons nextStepDisabled={true} />
        </>
    );
};
