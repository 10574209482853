import { nextStep, previousStep } from './clientRequestSlice';
import { useAppDispatch } from '../../../configuration/setup/hooks';

interface Props {
    previousStepDisabled?: boolean;
    nextStepDisabled: boolean;
}

export const NavigationButtons = (props: Props) => {
    const dispatch = useAppDispatch();
    const { previousStepDisabled = false, nextStepDisabled } = props;
    return (
        <div className={'btn-toolbar pull-right margin-top-5pct'}>
            <button
                type={'button'}
                className={'btn btn-primary'}
                onClick={() => dispatch(previousStep())}
                disabled={previousStepDisabled}
            >
                Previous
            </button>
            <button
                type={'button'}
                className={'btn btn-primary'}
                onClick={() => dispatch(nextStep())}
                disabled={nextStepDisabled}
            >
                Next
            </button>
        </div>
    );
};
