import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Api } from './apiRepository';
import type { Client } from '../App.types';
import type { ChangeEvent } from 'react';
import type { ClientRequestState } from './clientRequest.types';

interface ApiSelectionState {
    selected: boolean;
    read: boolean;
    write: boolean;
}

type PartialApiSelectionState = Partial<ApiSelectionState>;

export type ApiState = { [key in Api]?: ApiSelectionState };

const allFalse: ApiSelectionState = {
    selected: false,
    read: false,
    write: false,
};

const initialApiState: ApiState = {
    [Api.Assets]: allFalse,
    [Api.Maintenance]: allFalse,
    [Api.Tacho]: allFalse,
    [Api.DrivingTime]: allFalse,
    [Api.IotEvents]: allFalse,
};

const initialState: ClientRequestState = {
    currentStep: 0,
    clientName: '',
    clientEmail: '',
    clientDescription: '',
    clientDetailedDescription: '',
    clientType: 'Partner',
    clientTrusted: false,
    isMobileAppClient: false,
    associatedAccountId: undefined,
    requiresPermissions: false,
    apiSelectionState: initialApiState,
    showCustomScopes: false,
    customScopes: '',
};

const clientRequestSlice = createSlice({
    name: 'clientRequest',
    initialState: initialState,
    reducers: {
        nextStep(state) {
            state.currentStep = state.currentStep + 1;
        },
        previousStep(state) {
            state.currentStep = state.currentStep - 1;
        },
        updateClientName(state, action: PayloadAction<string>) {
            state.clientName = action.payload;
        },
        updateClientEmail(state, action: PayloadAction<string>) {
            state.clientEmail = action.payload;
        },
        updateClientDescription(state, action: PayloadAction<string>) {
            state.clientDescription = action.payload;
        },
        updateClientDetailedDescription(state, action: PayloadAction<string>) {
            state.clientDetailedDescription = action.payload;
        },
        updateClientType(state, action: PayloadAction<Client>) {
            state.clientType = action.payload;
        },
        updateClientAccount(state, action: PayloadAction<ChangeEvent<HTMLInputElement>>) {
            state.associatedAccountId = action.payload.target.value;
        },
        resetClientAccount(state) {
            state.associatedAccountId = undefined;
        },
        toggleClientTrusted(state) {
            state.clientTrusted = !state.clientTrusted;
        },
        toggleIsMobileApp(state) {
            state.isMobileAppClient = !state.isMobileAppClient;
        },
        toggleClientRequestPermissions(state) {
            state.requiresPermissions = !state.requiresPermissions;
        },
        setRequiresPermissions(state, action: PayloadAction<boolean>) {
            state.requiresPermissions = action.payload;
        },
        updateCustomScopes(state, action: PayloadAction<string>) {
            state.customScopes = action.payload;
        },
        setShowCustomScopes(state, action: PayloadAction<boolean>) {
            state.showCustomScopes = action.payload;
        },
        toggleApiState(state, action: PayloadAction<{ api: Api; partialApiSelectionState: PartialApiSelectionState }>) {
            state.apiSelectionState = {
                ...state.apiSelectionState,
                [action.payload.api]: {
                    ...state.apiSelectionState[action.payload.api],
                    ...action.payload.partialApiSelectionState,
                },
            };
        },
    },
});

export const {
    nextStep,
    previousStep,
    updateClientName,
    updateClientEmail,
    updateClientDescription,
    updateClientDetailedDescription,
    updateClientType,
    updateClientAccount,
    updateCustomScopes,
    setShowCustomScopes,
    toggleClientTrusted,
    toggleApiState,
    resetClientAccount,
    setRequiresPermissions,
    toggleClientRequestPermissions,
    toggleIsMobileApp,
} = clientRequestSlice.actions;
export const clientRequestReducer = clientRequestSlice.reducer;
