import type { AccessToken } from './tokenSlice';

export interface StorageUtil {
    discardAccessToken: () => void;
    getAccessToken: () => AccessToken;
    hasAccessToken: () => boolean;
    saveAccessToken: (token: AccessToken) => void;
}

export const configureStorage = (): StorageUtil => {
    let storedAccessToken: AccessToken = null;
    return {
        discardAccessToken: () => {
            storedAccessToken = null;
        },
        getAccessToken: () => storedAccessToken,
        hasAccessToken: () => Boolean(storedAccessToken),
        saveAccessToken: token => {
            storedAccessToken = token;
        },
    };
};

export const accessToken = configureStorage();
