import { useEffect, useState } from 'react';
import { type Api, ApiRepository } from './apiRepository';
import { getApi } from './clientRequest.selectors';
import { toggleApiState } from './clientRequestSlice';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { ToggleButtonExtended } from './ToggleButtonExtended';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

const ApiSelection = (props: { selectedApi: Api }) => {
    const dispatch = useAppDispatch();

    const { selectedApi } = props;

    const currentApiState = useAppSelector(getApi)(selectedApi);
    const availableAccessTypes = ApiRepository.getAvailableAccessTypes(selectedApi);

    return currentApiState ? (
        <div className={'display-flex margin-bottom-10'}>
            <div className={'flex-1-0'}>
                <Checkbox
                    checked={currentApiState.selected}
                    onClick={() =>
                        dispatch(
                            toggleApiState({
                                api: selectedApi,
                                partialApiSelectionState: { selected: !currentApiState.selected },
                            })
                        )
                    }
                >
                    {selectedApi}
                </Checkbox>
            </div>
            <div className={'btn-toolbar'} style={{ visibility: currentApiState.selected ? 'visible' : 'hidden' }}>
                <ToggleButtonExtended
                    disabled={availableAccessTypes ? availableAccessTypes.includes('read') : true}
                    active={currentApiState.read}
                    onClick={() =>
                        dispatch(
                            toggleApiState({
                                api: selectedApi,
                                partialApiSelectionState: { read: !currentApiState.read },
                            })
                        )
                    }
                >
                    Read
                </ToggleButtonExtended>
                <ToggleButtonExtended
                    disabled={availableAccessTypes ? availableAccessTypes.includes('write') : true}
                    active={currentApiState.write}
                    onClick={() =>
                        dispatch(
                            toggleApiState({
                                api: selectedApi,
                                partialApiSelectionState: { write: !currentApiState.write },
                            })
                        )
                    }
                >
                    Write
                </ToggleButtonExtended>
            </div>
        </div>
    ) : null;
};

export const PartnerClientApiSelection = () => {
    const [displayedApis, setDisplayedApis] = useState<Array<{ name: Api }>>([]);

    useEffect(() => {
        setDisplayedApis(ApiRepository.getApiSpecByAudience('external-partner'));
    }, []);

    return (
        <>
            <div className={'margin-bottom-25'}>
                <h4>Select required APIs and Access Patterns</h4>
            </div>
            {displayedApis.map(api => (
                <ApiSelection selectedApi={api.name} key={api.name} />
            ))}
        </>
    );
};
